import type { ReactElement, MouseEventHandler, MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useWishlistContext } from '../../../../utilities/context/dynamic/WishlistContext';
import { animations } from '../../../shared/core/styles';
import {
  SiteType,
  SizeType,
  useAddToWishlistMutation,
  useRemoveFromWishlistMutation,
} from '../../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import HeartEmptyIcon from '../../../shared/core/icons/HeartEmptyIcon';
import HeartFullIcon from '../../../shared/core/icons/HeartFullIcon';
import { useSizeContext } from '../../../../utilities/context/dynamic/SizeContext';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';

export type WishlistButtonType = 'PDP' | 'QuickView';

const baseWishlistButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const styles = {
  GStarIconWrapper: css<{ $wishlistButtonType: WishlistButtonType }>`
    width: ${({ $wishlistButtonType }) => ($wishlistButtonType === 'PDP' ? '16px' : '24px')};
    height: ${({ $wishlistButtonType }) => ($wishlistButtonType === 'PDP' ? '16px' : '24px')};
  `,
  AafIconWrapper: css<{ $wishlistButtonType: WishlistButtonType }>`
    width: 16px;
    height: 16px;
  `,

  GStarWishlistButton: css`
    ${baseWishlistButton}
    width: 44px;
    height: 40px;
  `,

  AafWishlistButton: css`
    ${baseWishlistButton}
    width: 48px;
    height: 48px;
    padding: 8px;
  `,
};

const IconWrapperStyles: Record<SiteType, RuleSet<{ $wishlistButtonType: WishlistButtonType }>> = {
  [SiteType.Gstar]: styles.GStarIconWrapper,
  [SiteType.Outlet]: styles.GStarIconWrapper,
  [SiteType.Employeeshop]: styles.GStarIconWrapper,
  [SiteType.Aaf]: styles.AafIconWrapper,
};

const WishlistButtonStyles: Record<SiteType, RuleSet<{ $wishlisting: boolean }>> = {
  [SiteType.Gstar]: styles.GStarWishlistButton,
  [SiteType.Outlet]: styles.GStarWishlistButton,
  [SiteType.Employeeshop]: styles.GStarWishlistButton,
  [SiteType.Aaf]: styles.AafWishlistButton,
};

interface WishlistActionProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  wishlistButtonType?: WishlistButtonType;
  baseProduct?: string | null;
  code?: string | null;
  sizeType?: SizeType | null;
}

const pulseAnimation = css`
  animation: ${animations.pulse} 0.8s infinite ease-in-out;
`;

const S = {
  ButtonWrapper: styled.div<{ $wishlistButtonType: WishlistButtonType }>`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin-left: 8px;
    border: ${({ $wishlistButtonType, theme }) =>
      $wishlistButtonType === 'PDP' ? `1px solid ${theme.colors.content.primary}}` : 'none'};
  `,

  WishlistButton: styled.button<{ $wishlisting: boolean }>`
    display: block;
    border: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.content.primary};
    cursor: ${({ $wishlisting }) => ($wishlisting ? 'not-allowed' : 'pointer')};
    ${({ $wishlisting }) => ($wishlisting ? pulseAnimation : 'animation: none')};

    @media (prefers-reduced-motion) {
      animation: none;
    }

    ${({ theme }) => WishlistButtonStyles[theme.siteType]}
  `,

  IconWrapper: styled.span<{ $wishlistButtonType: WishlistButtonType }>`
    display: flex;
    ${({ theme }) => IconWrapperStyles[theme.siteType]}
  `,
};

export const WishlistAction = ({
  className,
  onClick,
  wishlistButtonType = 'PDP',
  baseProduct,
  code,
  sizeType,
}: WishlistActionProps): ReactElement => {
  const { colors } = useTheme();
  const {
    configuration: { enableWishlist },
    accessibilityLabels,
  } = useStaticContext();
  const { miniWishlist, fetchWishlist } = useWishlistContext();
  const {
    gridValues: { gridValue1, gridValue2 },
  } = useSizeContext();
  const { locale, siteType } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [wishlisting, setWishlisting] = useState(false);
  const [addToWishlistMutation] = useAddToWishlistMutation();
  const [removeFromWishlistMutation] = useRemoveFromWishlistMutation();
  const { commonData } = useUserContext();

  let variantCode = code || baseProduct;

  if (sizeType === SizeType.NumericDouble && gridValue1 && gridValue2) {
    variantCode = `${baseProduct || code}-${gridValue1}${gridValue2}`;
  } else if (gridValue1) {
    variantCode = `${baseProduct || code}-${gridValue1}`;
  }

  const doWishlist = async () => {
    setWishlisting(true);

    try {
      if (isInWishlist) {
        // Remove from wishlist
        const response = (
          await removeFromWishlistMutation({
            variables: {
              siteType,
              code: baseProduct || code,
              locale,
              csrfToken: commonData?.csrfToken,
            },
          })
        ).data;

        if (response?.removeFromWishlist === true) {
          await fetchWishlist(true);
          pushToDataLayer({
            events: {
              category: 'wishlist',
              action: 'remove',
              label: (baseProduct || code)?.toLowerCase(),
            },
            event: 'wishlist-remove',
          });
        }
      } else {
        // Add to wishlist
        const response = (
          await addToWishlistMutation({
            variables: {
              siteType,
              code: baseProduct || code,
              locale,
              csrfToken: commonData?.csrfToken,
            },
          })
        ).data;

        if (response?.addToWishlist === true) {
          await fetchWishlist(true);
          pushToDataLayer({
            events: {
              category: 'wishlist',
              action: 'add',
              label: (baseProduct || code)?.toLowerCase(),
            },
            event: 'wishlist-add',
          });
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error - Wishlist mutation', e);
    }

    setWishlisting(false);
  };

  useEffect(() => {
    setIsInWishlist(
      (miniWishlist?.products?.findIndex(
        wishlistProduct =>
          wishlistProduct?.code?.toLowerCase() === (baseProduct || code)?.toLowerCase()
      ) ?? -1) >= 0
    );
  }, [miniWishlist?.products, baseProduct, code]);

  return enableWishlist && variantCode ? (
    <S.ButtonWrapper $wishlistButtonType={wishlistButtonType}>
      <S.WishlistButton
        aria-pressed={isInWishlist}
        data-state={isInWishlist ? 'on' : 'off'}
        $wishlisting={wishlisting}
        className={className}
        aria-label={
          isInWishlist
            ? accessibilityLabels?.removeFromWishlist
            : accessibilityLabels?.addToWishlist
        }
        onClick={(e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
          onClick?.(e);
          doWishlist();
        }}
        data-testid="wishlist-action-button"
      >
        <S.IconWrapper $wishlistButtonType={wishlistButtonType}>
          {isInWishlist ? (
            <HeartFullIcon color={colors.content.primary} />
          ) : (
            <HeartEmptyIcon color={colors.content.primary} />
          )}
        </S.IconWrapper>
      </S.WishlistButton>
    </S.ButtonWrapper>
  ) : (
    <></>
  );
};
