import styled from 'styled-components';
import { Image as DCImage, ImageUrlBuilder } from 'dc-delivery-sdk-js';
import { SPECIAL_MATERIAL_IMAGE } from '../../../../utilities/constants';
import { useImageSwitchContext } from '../../../../utilities/context/dynamic/ImageSwitchContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import type { ImageEntry } from '../../../../utilities/graphql/codegen';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { ImageWithLoaderIndicator } from '../../common/ImageWithLoaderIndicator';
import { colors } from '../../core/styles';
import type { TileType } from './types';
import { useExperienceContext } from '../../../../utilities/context/dynamic/ExperienceOptionsProvider';
import type { Image, ImageLink } from '../../../../amplienceTypes/schemas/exported/product-schema';

const S = {
  ProductImageWrapper: styled.div`
    background-color: ${colors.COLOR_GREY_200};
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 0.75;

    @supports not (aspect-ratio: 0.75) {
      height: 0;
      padding-bottom: 135%;

      > * {
        position: absolute;
        inset: 0;
        height: 100%;
      }
    }
  `,

  HoveredImage: styled(ImageWithLoaderIndicator)`
    background-color: transparent;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  `,
};

interface TileImageProps {
  singleProduct: RecentlyViewedProduct;
  variantHovered: number;
  productHovered: boolean;
  tileType: TileType;
  override?: Image;
  overrideHovered?: Image;
}

const hasSpecialImage = (materialGroup = '') => SPECIAL_MATERIAL_IMAGE.includes(materialGroup);

const srcSizes = [148, 203, 232, 288, 328, 399, 459, 519, 619, 689, 739];

export const TileImage = ({
  singleProduct,
  variantHovered,
  productHovered,
  tileType,
  override,
  overrideHovered,
}: TileImageProps) => {
  const { experienceOptions } = useExperienceContext();
  const {
    configuration: { enableDarkBgImages, productTileConfiguration },
  } = useStaticContext();

  const { viewAs } = useImageSwitchContext();

  const { showHoverImageOnPlp, showHoverImageOnRvp, showHoverImageOnRecommendations } =
    productTileConfiguration || {};

  const hoverConfigMap: Record<TileType, boolean> = {
    plp: showHoverImageOnPlp ?? false,
    rvp: showHoverImageOnRvp ?? false,
    recommendation: showHoverImageOnRecommendations ?? false,
    amplience: true,
  };

  const shouldDisplayHover = (hoverConfigMap[tileType] ?? false) && viewAs === 'outfit';

  const overrideImageUrlBuilder = (imageData: ImageLink) => {
    const image = new DCImage(imageData, {});

    if (image) {
      const src = new ImageUrlBuilder(image)
        .width(1000)
        .parameter('sm', 'aspect')
        .parameter('aspect', '3:4')
        .parameter('fmt', 'auto')
        .build();
      const srcSet = srcSizes
        .map(
          size =>
            `${new ImageUrlBuilder(image)
              .width(size)
              .parameter('sm', 'aspect')
              .parameter('aspect', '3:4')
              .parameter('fmt', 'auto')
              .build()} ${size}w`
        )
        .join(', ');

      return [src, srcSet];
    }

    return [];
  };

  const makeImageUrl = (imageUrl: string, imageParams: string) =>
    imageUrl.replace('{{dimensions}}', imageParams);

  const findRelevantImage = (key: string) =>
    singleProduct.simplifiedImages?.entry?.find((entry: ImageEntry | null) => entry?.key === key)
      ?.value?.url;

  const E01 = findRelevantImage('E01');
  const E02 = findRelevantImage('E02');
  let E04;
  const F01 = findRelevantImage('F01');
  const F02 = findRelevantImage('F02');
  let F04;
  const M02 = findRelevantImage('M02');
  const Z02 = findRelevantImage('Z02');

  const primaryImageOverride = findRelevantImage(override?.type || '');
  const hoverImageOverride = findRelevantImage(overrideHovered?.type || '');

  if (singleProduct) {
    if (experienceOptions?.tk33?.experienceId) {
      E04 = findRelevantImage('E04');
      F04 = findRelevantImage('F04');
    }
  }

  let primaryImage = primaryImageOverride || (enableDarkBgImages && E01 ? E01 : F01);

  if (!primaryImage && singleProduct.primaryImage) {
    primaryImage = singleProduct.primaryImage.url;
  }

  let hoverImage: string | null | undefined;
  const hasSpecialImageFlag = hasSpecialImage(singleProduct?.materialGroup || '');

  if (enableDarkBgImages) {
    hoverImage = hasSpecialImageFlag && M02 ? M02 : E02;
  } else {
    hoverImage = hasSpecialImageFlag && Z02 ? Z02 : F02;
  }

  if (experienceOptions?.tk33?.experienceId) {
    hoverImage = enableDarkBgImages ? E04 : F04;
  }

  if (!hoverImage) {
    hoverImage = E02 || F02;
  }

  if (!hoverImage && singleProduct.hoverImage) {
    hoverImage = singleProduct.hoverImage.url;
  }

  hoverImage = hoverImageOverride || hoverImage;

  if (!primaryImage) {
    return <S.ProductImageWrapper />;
  }

  let primarySrc;
  let primarySrcSet;

  if (override?.override) {
    [primarySrc, primarySrcSet] = overrideImageUrlBuilder(override.override[0]);
  } else {
    const mainImageUrl = viewAs === 'product' && hoverImage ? hoverImage : primaryImage;

    primarySrc = makeImageUrl(mainImageUrl, 'w_1000,ar_0.75,q_auto');
    primarySrcSet = srcSizes
      .map(size => `${makeImageUrl(mainImageUrl, `w_${size},ar_0.75,q_auto`)} ${size}w`)
      .join(', ');
  }

  let hoverSrc;
  let hoverSrcSet;

  if (overrideHovered?.override) {
    [hoverSrc, hoverSrcSet] = overrideImageUrlBuilder(overrideHovered.override[0]);
  } else if (hoverImage) {
    hoverSrc = makeImageUrl(hoverImage, 'w_1000,ar_0.75,q_auto');
    hoverSrcSet = hoverImage
      ? srcSizes
          .map(size => `${makeImageUrl(hoverImage, `w_${size},ar_0.75,q_auto`)} ${size}w`)
          .join(', ')
      : '';
  }

  return (
    <S.ProductImageWrapper>
      <ImageWithLoaderIndicator
        src={primarySrc}
        alt={singleProduct.name ?? ''}
        loading="lazy"
        srcSet={primarySrcSet}
        sizes="(max-width: 767px) 47vw, (max-width: 1024px) 30vw, 24vw"
        intersectionObserverOptions={{
          rootMargin: '30% 0%',
          threshold: 0,
        }}
      />

      {(variantHovered >= 0 || productHovered) && hoverSrc && shouldDisplayHover ? (
        <S.HoveredImage
          src={hoverSrc}
          alt={singleProduct.name ?? ''}
          loading="eager"
          srcSet={hoverSrcSet}
          sizes="(max-width: 767px) 47vw, (max-width: 1024px) 30vw, 24vw"
          shimmerDuration="0s"
          fadeInOnLoad={false}
        />
      ) : (
        <></>
      )}
    </S.ProductImageWrapper>
  );
};
