import type { ReactElement, RefObject, SetStateAction } from 'react';
import { useRef } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import type { Product, StyleVariant } from '../../../utilities/graphql/codegen';
import { LowestPriceDisplay } from '../../../utilities/graphql/codegen';
import { colors, media } from '../core/styles';
import { VariantAreaDiv } from './tile/styles';
import { ImageWithLoaderIndicator } from '../common/ImageWithLoaderIndicator';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { hideScrollBar } from '../common/styles';
import { useOverflowIndicators } from '../../../utilities/hooks/useOverflowIndicators';
import { usePointerAutoscroll } from '../../../utilities/hooks/usePointerAutoscroll';
import { VariantAreaSkeleton } from './VariantAreaSkeleton';

const S = {
  Variants: styled.div`
    overflow-x: scroll;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    ${hideScrollBar}

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      overflow-x: visible;
    }
  `,

  VariantsScrollArea: styled.div.attrs<{
    $align: 'center' | 'left';
  }>(({ $align }) => ({
    style: {
      textAlign: $align,
    },
  }))`
    /* stylelint-disable declaration-property-value-no-unknown, custom-property-no-missing-var-function */

    ${hideScrollBar}

    position: relative;
    padding: 0;
    min-width: 100%;
    white-space: nowrap;
    text-align: center;
    overflow: scroll;
    font-size: 0;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      will-change: transform;
    }

    --_scrollarea-overflow-start: 0.01px;
    --_scrollarea-overflow-end: 0.01px;

    &[data-has-overflow-start='true'] {
      --_scrollarea-overflow-start: 24px;
    }

    &[data-has-overflow-end='true'] {
      --_scrollarea-overflow-end: 24px;
    }

    mask-image: linear-gradient(
      90deg,
      transparent 0%,
      black var(--_scrollarea-overflow-start),
      black calc(100% - var(--_scrollarea-overflow-end)),
      transparent 100%
    );

    @property --_scrollarea-overflow-start {
      syntax: '<length-percentage>';
      inherits: true;
      initial-value: 0.01px;
    }

    @property --_scrollarea-overflow-end {
      syntax: '<length-percentage>';
      inherits: true;
      initial-value: 0.01px;
    }

    @media (prefers-reduced-motion: no-preference), (prefers-reduced-motion: reduce) {
      transition: --_scrollarea-overflow-start 0.1s linear, --_scrollarea-overflow-end 0.1s linear;
      will-change: mask-image;
    }
  `,

  Variant: styled.button`
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    box-shadow: 0 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-bottom: 4px;
    box-sizing: content-box;

    &:not(:first-child) {
      padding-left: 12px;
    }

    & > * {
      overflow: hidden;
      border-radius: 50%;
    }

    &[data-state='on']::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -4px;
      max-width: 24px;
      width: 100%;
      height: 2px;
      background-color: ${colors.BLUE_CHARCOAL};
    }
  `,

  Color: styled.div<{ $color: string }>`
    width: 100%;
    height: 100%;
    background: ${({ $color }) => $color};
  `,
};

interface VariantAreaProps {
  product: Product;
  setVariantHovered: (variantHovered: SetStateAction<number>) => void;
  variants: StyleVariant[] | undefined;
  wrapperRef: RefObject<HTMLElement>;
  className?: string;
  onVariantLinkClick?: () => void;
}

const srcSizes = [24, 48, 72];

export const VariantArea = ({
  product,
  setVariantHovered,
  variants,
  wrapperRef,
  className,
  onVariantLinkClick,
}: VariantAreaProps): ReactElement => {
  const {
    configuration: { lowestPriceDisplay },
  } = useStaticContext();
  const variantsScrollAreaRef = useRef<HTMLDivElement>(null);
  const isLowestPriceHidden: boolean = lowestPriceDisplay === LowestPriceDisplay.Never;

  useOverflowIndicators({ targetElementRef: variantsScrollAreaRef });
  usePointerAutoscroll({ parentElementRef: wrapperRef, targetElementRef: variantsScrollAreaRef });

  const makeImageUrl = (imageUrl: string, imageParams: string) =>
    imageUrl.replace('{{dimensions}}', imageParams);

  const filteredVariants = variants?.filter(
    ({ facetColorStyle, images }) => !!facetColorStyle || !!images?.swatch
  );

  return (
    <VariantAreaDiv className={className}>
      <S.Variants>
        <S.VariantsScrollArea
          ref={variantsScrollAreaRef}
          $align={isLowestPriceHidden ? 'center' : 'left'}
        >
          {filteredVariants?.length !== 0 ? (
            filteredVariants?.map(({ productUrl, name, images, facetColorStyle, color }, i) => {
              let srcSet = '';

              if (images?.swatch) {
                srcSet = srcSizes
                  .map(
                    size =>
                      `${makeImageUrl(images.swatch as string, `w_${size},ar_1,q_auto`)} ${size}w`
                  )
                  .join(', ');
              }

              const label = `${name} ${color}`;

              return (
                productUrl && (
                  /**
                   * `legacyBehavior` passes the the link to the button as a click handler
                   * anchor elements can't be nested as the tile wrapper is an anchor for the product tile
                   * also done so to reduce the number of wrappers, be aware of this when refactoring
                   */
                  <Link key={i} href={productUrl} prefetch={false} legacyBehavior>
                    <S.Variant
                      data-testid="product-tile-style-variant"
                      aria-label={label}
                      data-state={
                        productUrl?.toLowerCase() === product.url?.toLowerCase() ? 'on' : 'off'
                      }
                      onMouseEnter={() => setVariantHovered(i)}
                      onFocus={() => setVariantHovered(i)}
                      onMouseLeave={() => setVariantHovered(-1)}
                      onBlur={() => setVariantHovered(-1)}
                      onClick={onVariantLinkClick}
                    >
                      {images?.swatch ? (
                        <ImageWithLoaderIndicator
                          src={makeImageUrl(images.swatch, 'w_24,ar_1,q_auto')}
                          alt={label}
                          shimmerDuration="1s"
                          sizes="(-webkit-min-device-pixel-ratio: 2) 48px, (-webkit-min-device-pixel-ratio: 3) 72px, 24px"
                          srcSet={srcSet}
                          loading="lazy"
                          intersectionObserverOptions={{
                            /**
                             * 162px is roughly half of tile height
                             */
                            rootMargin: '162px 0px',
                            threshold: 0,
                          }}
                        />
                      ) : (
                        <S.Color role="img" aria-label={label} $color={facetColorStyle as string} />
                      )}
                    </S.Variant>
                  </Link>
                )
              );
            })
          ) : (
            <VariantAreaSkeleton />
          )}
        </S.VariantsScrollArea>
      </S.Variants>
    </VariantAreaDiv>
  );
};
