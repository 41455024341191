import styled from 'styled-components';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { Typography } from '../../core/typography/Typography';
import { colors } from '../../core/styles';

const S = {
  SigningsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    flex-shrink: 1;
    gap: 2px;
    padding: 4px;
    box-sizing: border-box;
    max-width: 100%;
    min-width: 0;
  `,

  Signing: styled(Typography)<{ $backgroundColor?: string | null }>`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size[2]};
    min-height: 24px;
    padding: 0 6px;
    max-width: 100%;
    box-sizing: border-box;
    font-weight: ${({ theme }) => theme.overrides.font?.weight.regular};
    line-height: 1.4;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
  `,
};

export const TileSignings = ({
  signingEnabled,
  singleProduct,
}: {
  signingEnabled: boolean;
  singleProduct: RecentlyViewedProduct;
}) => {
  if (!(signingEnabled && singleProduct?.signings?.length)) {
    return <></>;
  }

  return (
    <S.SigningsWrapper data-testid="product-tile-signings-wrapper">
      {singleProduct.signings
        .filter(signing => !!signing?.label)
        .map(signing => (
          <S.Signing
            key={signing?.label}
            variant="subtitle"
            component="span"
            alignment="center"
            color={signing?.productSigningTextColour || colors.BLUE_CHARCOAL}
            $backgroundColor={signing?.productSigningBackgroundColour || colors.WHITE}
            testId="product-tile-signing"
            data-cs-capture=""
            data-priority={signing?.priority}
            data-code={signing?.code}
          >
            {signing?.label}
          </S.Signing>
        ))}
    </S.SigningsWrapper>
  );
};
