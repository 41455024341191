import styled from 'styled-components';
import { colors, media } from '../../shared/core/styles';
import { shimmerAnimation } from '../../shared/common/styles';

const S = {
  SkeletonGridItem: styled.article`
    /* tile size at screen width: 430px */
    padding-bottom: calc(350 / 203 * 100%);
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      top: 0;
    }

    --mobile-hard-stop-1: 87%;
    --mobile-hard-stop-2: 86.1%;
    --mobile-hard-stop-3: 74.9%;
    --mobile-hard-stop-4: 74%;
    --mobile-hard-stop-5: 67.9%;
    --mobile-hard-stop-6: 67%;

    ${shimmerAnimation}

    @media ${({ theme }) => media(theme).noHover} {
      background: linear-gradient(
        180deg,
        ${colors.COLOR_GREY_200} 0%,
        ${colors.COLOR_GREY_200} var(--mobile-hard-stop-6),
        ${colors.WHITE} var(--mobile-hard-stop-6),
        ${colors.WHITE} var(--mobile-hard-stop-5),
        ${colors.COLOR_GREY_200} var(--mobile-hard-stop-5),
        ${colors.COLOR_GREY_200} var(--mobile-hard-stop-4),
        ${colors.WHITE} var(--mobile-hard-stop-4),
        ${colors.WHITE} var(--mobile-hard-stop-3),
        ${colors.COLOR_GREY_200} var(--mobile-hard-stop-3),
        ${colors.COLOR_GREY_200} var(--mobile-hard-stop-2),
        ${colors.WHITE} var(--mobile-hard-stop-2),
        ${colors.WHITE} var(--mobile-hard-stop-1),
        ${colors.COLOR_GREY_200} var(--mobile-hard-stop-1),
        ${colors.COLOR_GREY_200} 100%
      );
    }

    @media ${({ theme }) => media(theme).canHover} {
      --desktop-hard-stop-1: 94%;
      --desktop-hard-stop-2: 93%;
      --desktop-hard-stop-3: 89%;
      --desktop-hard-stop-4: 88%;

      /* tile size at screen width: 1240px */
      padding-bottom: calc(433 / 289 * 100%);
      background: linear-gradient(
        180deg,
        ${colors.COLOR_GREY_200} 0%,
        ${colors.COLOR_GREY_200} var(--desktop-hard-stop-4),
        ${colors.WHITE} var(--desktop-hard-stop-4),
        ${colors.WHITE} var(--desktop-hard-stop-3),
        ${colors.COLOR_GREY_200} var(--desktop-hard-stop-3),
        ${colors.COLOR_GREY_200} var(--desktop-hard-stop-2),
        ${colors.WHITE} var(--desktop-hard-stop-2),
        ${colors.WHITE} var(--desktop-hard-stop-1),
        ${colors.COLOR_GREY_200} var(--desktop-hard-stop-1),
        ${colors.COLOR_GREY_200} 100%
      );
    }
  `,
};

export const SkeletonTile = () => <S.SkeletonGridItem />;
